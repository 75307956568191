import React from 'react';
import { useSelector } from 'react-redux';
import { getHomePageBanner } from 'store/homePage/homePageSelectors';
import { Box, Text } from '@qga/roo-ui/components';
import { Tabs, TabList, Tab, TabPanel } from '@reach/tabs';
import { useRouter } from 'next/router';
import HotelsTab from './HotelsTab';
import AirbnbTab from 'components/AirbnbTab';
import { TabsWrapper, HeroImage, SearchWrapper, ResponsiveTabs, TabButton, TabPanels, LabelContainer } from './HomePageSearchPanel.style';
import { useDataLayer } from 'hooks/useDataLayer';
import { getImage } from 'lib/sanity';
import { ACTIVITIES_API_URL, PUBLIC_PATH } from 'config';
import { getIsMobileApp } from 'store/ui/uiSelectors';
import { rem } from 'polished';
import { useFeature } from '@optimizely/react-sdk';
import useTabClickEvent from 'hooks/useTabClickEvent';

const DEFAULT_IMAGE_SRC = `${PUBLIC_PATH}images/placeholder-desktop-image.jpg`;

const HomePageSearchPanel = () => {
  const router = useRouter();
  const { emitInteractionEvent } = useDataLayer();
  const heroImage = useSelector(getHomePageBanner);
  const isMobileApp = useSelector(getIsMobileApp);
  const heroImageSrc = getImage(heroImage?.asset);
  const isAirbnbSearch = router?.query?.searchType === 'airbnb';
  const defaultIndex = isAirbnbSearch ? 1 : 0;
  const { tabClickEvent } = useTabClickEvent();

  const [isActivitiesEnabled] = useFeature('qantas-activities-customer');

  const onTabClicked = (tabIndex: React.SetStateAction<number>) => {
    // Keep track of what tab is selected using query strings
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { searchType, ...oldQuery } = router?.query || {};
    const newQuery = tabIndex === 1 ? { searchType: 'airbnb' } : tabIndex === 2 ? { searchType: 'activities' } : {};
    router.push({ pathname: router.pathname || '/', query: { ...oldQuery, ...newQuery } }, undefined, { shallow: true });
    tabClickEvent({
      itemText: `Hotels ${tabIndex === 1 ? 'Airbnb ' : tabIndex === 2 ? 'Activities ' : ''}Selected`,
      itemName: `Index ${tabIndex}`,
    });
    emitInteractionEvent({ type: 'Search', value: `Hotels ${tabIndex === 1 ? 'Airbnb ' : tabIndex === 2 ? 'Activities ' : ''}Selected` });
  };

  return (
    <Box pt={['50px', '50px', 0]} position="relative">
      <HeroImage
        src={heroImageSrc?.xLarge || DEFAULT_IMAGE_SRC}
        role="img"
        aria-hidden="true"
        alt="Book hotels and accommodation in Australia"
        height={['168px', '168px', '100%']}
      />
      <TabsWrapper px={3}>
        <Tabs onChange={onTabClicked} defaultIndex={defaultIndex} index={defaultIndex}>
          <TabList as={ResponsiveTabs}>
            <Tab
              as={TabButton}
              data-testid="hotels-tab"
              aria-label="Search for hotels"
              borderRadius="default"
              isLeftTab={true}
              isMiddleTab={false}
              tabIndex={0}
            >
              <LabelContainer isActive={defaultIndex === 0}>
                <Text aria-label="Search for hotels or destinations" m={0} fontSize="base" lineHeight={rem(45)}>
                  Hotels
                </Text>
              </LabelContainer>
            </Tab>
            <Tab
              as={TabButton}
              data-testid="airbnb-tab"
              aria-label="Search for Air BNBs"
              borderRadius="defaultRoundRightOnly"
              isMiddleTab={isActivitiesEnabled}
              isLeftTab={false}
              tabIndex={0}
            >
              <LabelContainer isActive={defaultIndex === 1}>
                <Text aria-label="Search for Air BNBs" m={0} fontSize="base" lineHeight={rem(45)}>
                  Airbnb
                </Text>
              </LabelContainer>
            </Tab>
            {isActivitiesEnabled && (
              <Tab
                as={TabButton}
                data-testid="activities-tab"
                aria-label="Search for activities"
                borderRadius="default"
                isLeftTab={false}
                isMiddleTab={false}
                tabIndex={0}
                onMouseDown={(e: { preventDefault: () => void }) => {
                  e.preventDefault();
                  setTimeout(() => {
                    window.open(ACTIVITIES_API_URL, '_blank');
                  }, 500);
                }}
              >
                <LabelContainer isActive={false}>
                  <Text aria-label="Search for activities" m={0} fontSize="base" lineHeight={rem(45)}>
                    Activities
                  </Text>
                </LabelContainer>
              </Tab>
            )}
          </TabList>
          <SearchWrapper boxShadow={isMobileApp ? 'none' : 'hard'}>
            <TabPanels>
              {/* The <FocusManager /> does not work correctly if it is a child of a parent with tabIndex=0 */}
              <TabPanel hidden={isAirbnbSearch} tabIndex={isAirbnbSearch ? -1 : undefined} data-testid="hotels-panel">
                <HotelsTab />
              </TabPanel>
              <TabPanel hidden={!isAirbnbSearch} tabIndex={!isAirbnbSearch ? -1 : undefined} data-testid="airbnb-panel">
                <AirbnbTab isLandingPage={false} />
              </TabPanel>
            </TabPanels>
          </SearchWrapper>
        </Tabs>
      </TabsWrapper>
    </Box>
  );
};

export default HomePageSearchPanel;
